import React from 'react'
import { RichText } from 'prismic-reactjs'
import { RawContentSection } from './RawContentSection'
import { ImageWithCaption } from './ImageWithCaption'

const withSlices = Comp => props => {
  function getSliceContent(type, data) {
    switch (type) {
      case 'text_block':
        return <RawContentSection content={<RichText render={data.textBlock} />} />
      case 'image_with_caption':
        return (
          <ImageWithCaption
            caption={data.caption ? RichText.asText(data.caption) : null}
            fullWidth
            image={data.image ? data.image : null}
            title={data.title ? RichText.asText(data.title) : null}
          />
        )
      default:
        return null
    }
  }

  return <Comp getSliceContent={getSliceContent} {...props} />
}

export { withSlices }
