import React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'

import { ImageWithCaption } from '../components/ImageWithCaption'
import { Layout } from '../components/Layout'
import { RawContentSection } from '../components/RawContentSection'
import { SEO } from '../components/SEO'
import { withSlices } from '../components/withSlices'

import { toMakerData } from '../transform'

const Maker = withSlices(
  ({
    data: {
      prismic: { maker_page },
    },
    getSliceContent,
  }) => {
    const { slices, title } = toMakerData(maker_page)

    return (
      <Layout>
        <SEO title={RichText.asText(title)} />
        <section className="bg-white border-b-4 border-gray-200 px-4 py-8 md:py-12 lg:py-16">
          <div className="container">
            <div className="max-w-xl mx-auto">
              <h1 className="text-5xl md:text-6xl mb-4">{RichText.asText(title)}</h1>
              {slices.map(({ data, type }, i) => (
                <div key={i} className="mb-4">
                  {getSliceContent(type, data)}
                </div>
              ))}
            </div>
          </div>
        </section>
      </Layout>
    )
  }
)

export const query = graphql`
  {
    prismic {
      maker_page(lang: "en-nz", uid: "maker") {
        ...makerPage
      }
    }
  }
`

export default Maker
